<template>

  <div class="row" ref="ForumForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="name"
                      rules="required"
                      name="The Name"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="name"
                                name="name"
                                fou
                                @keyup="generateSomeFields"
                                v-model="formData.name">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="url"
                      rules="required"
                      name="The URL"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="URL"
                                name="url"
                                fou
                                v-model="formData.url">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="title"
                      rules=""
                      name="The Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="title"
                                name="title"
                                fou
                                v-model="formData.title">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="location"
                      rules=""
                      name="The Location"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="location"
                                name="location"
                                fou
                                v-model="formData.location">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="studio_link"
                      rules=""
                      name="The Studio Link"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Studio Link"
                                name="studio_link"
                                fou
                                v-model="formData.studio_link">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <label class="card-label">Date</label>
                    <fg-input v-model="formData.date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose session date"
                    >
                    </fg-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-select
                      name="local_hosts"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Type"
                      :input-classes="'select-default'"
                      :label="'Local Hosts'"
                      :list="builderData.localHostsList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.local_hosts">
                    </fg-select>
                  </div>
                  <div class="col-md-6">
                    <fg-select
                      name="organizers"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Type"
                      :input-classes="'select-default'"
                      :label="'Organizers'"
                      :list="builderData.organizersList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.organizers">
                    </fg-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-select
                      name="partners"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Type"
                      :input-classes="'select-default'"
                      :label="'Partners'"
                      :list="builderData.partnersList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.partners">
                    </fg-select>
                  </div>
                  <div class="col-md-6">
                    <fg-select
                      name="speakers"
                      size="large"
                      filterable
                      clearable
                      multiple
                      placeholder="Type"
                      :input-classes="'select-default'"
                      :label="'Speakers'"
                      :list="builderData.speakersList"
                      :listItemLabel="'name'"
                      :listItemValue="'id'"
                      v-model="formData.speakers">
                    </fg-select>
                  </div>
                </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>

              </div>
              <div class="col-md-12 col-sm-12">
                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
                <div class="form-group pt-4">
                  <label>Is Current ?</label>&nbsp;
                  <l-switch v-model="formData.is_current">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/forums/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import {Option, Select, Tooltip} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: '',
        title: "",
        url: "",
        date: '',
        content: '',
        location: "",
        speakers: [],
        organizers: [],
        sponsors: [],
        local_hosts: [],
        is_active: true,
        is_current: false,
        studio_link: '',
      },
      builderData: {
        speakersList: [],
        localHostsList:[],
        organizersList: [],
        partnersList: [],
      },

    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.forumForm
    });

    let data = {}
    this.axios.post("forums/builder", data).then((response) => {
      this.builderData.speakersList = response.data.speakers;
      this.builderData.localHostsList = response.data.localHosts;
      this.builderData.organizersList = response.data.organizers;
      this.builderData.partnersList = response.data.partners;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Edition";
        this.getForum();
      } else {
        this.editMode = false;
        this.formTitle = "Add Edition";
        this.loader.hide();
      }
    }).catch((error) => {
      console.error(error);
    })
  },
  methods: {
    getForum() {
      let data = {
        'id': this.id,
      };
      this.axios.post("forums/get", data).then((response) => {
        this.formData = response.data;
        this.formData.speakers = response.data.speakers.map(speaker => speaker.id);
        this.formData.organizers = response.data.organizers.map(organizer => organizer.id);
        this.formData.local_hosts = response.data.localHosts.map(local_host => local_host.id);
        this.formData.partners = response.data.partners.map(partner => partner.id);
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Edition Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("forums/update/" + this.id, this.formData);
        successMessage = "Edition Updated Successfully";
      } else {
        request = this.axios.post("forums/create", this.formData);
        successMessage = "Edition Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/forums/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    vueDate() {
      this.formData.date = (this.formData.date) ? this.formData.date.replace(' ', 'T') : this.formData.date;
    },
    laravelDate() {
      this.formData.date = (this.formData.date) ? this.formData.date.replace('T', ' ') : this.formData.date;
    },
    generateSomeFields() {
      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "_");
      }
      this.formData.url = newUrl;
    },
  }
}
</script>
